@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "./data/styleConstants.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* @font-face {
  font-family: "Inter";
  src: url("./assest/fonts/static/Inter-Bold.ttf");
  src: url("./assest/fonts/static/Inter-ExtraBold.ttf")
    format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container-fluid {
  padding: 0 110px;
}
ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
a,
img {
  border: 0px;
}
img {
  max-width: 100%;
}
a,
ul,
li {
  text-decoration: none;
}
a {
  outline: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}
a,
button {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  cursor: pointer;
}
p {
  color: var(--bg-light, $middleP);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* margin-bottom: 20px; */
  font-weight: normal;
}
h1 {
  // font-family: "Korto";
  font-family: "Inter";
  font-size: 64px;
  font-style: normal;
  letter-spacing: 0px;
}
h2 {
  font-family: "Korto";
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0px;
}
h3 {
  font-family: "DM Sans";
  font-size: 32px;
  letter-spacing: -4px;
}
h4 {
  color: var(--gray-900, $middleBlack);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
h5 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
h6 {
  font-size: 12px;
}
select::-ms-expand {
  display: none;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}

@media screen and (max-width: 1400px) {
  .time-line-div h1 {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 1399px) {
  h1 {
    font-size: 50px;
    line-height: 110%;
  }
  h2 {
    font-size: 35px;
    line-height: 42px;
  }
  h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1199px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 30px;
    line-height: 38px;
  }
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 30px;
  }

  .social-media-icons {
    .icon-wraper {
      width: 38px;
      height: 38px;
    }
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .social-media-icons {
    .icon-wraper {
      width: 38px;
      height: 38px;
    }
  }
}

@media screen and (max-width: 600px) {
  .social-media-icons {
    .icon-wraper {
      width: 38px;
      height: 38px;
    }
  }
}

@media screen and (max-width: 380px) {
}
