@import "../../data/styleConstants.scss";

.comingsoontwo-banner {
  background-image: url("../Images/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  min-height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    background: var(--Primary-Dark, #0b5ea5);
    z-index: 2;
  }

  .container-fluid {
    position: relative;
    z-index: 2;
    padding-left: 110px;
    padding-right: 110px;
  }
}

.comingsoon-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
}

@media screen and (max-width: 1440px) {
  // .comingsoontwo-banner {
  //   .container-fluid {
  //     padding-left: 90px;
  //     padding-right: 90px;
  //   }
  // }
  .comingsoontwo-banner {
    height: 100%;
  }
}

// @media screen and (max-width: 1400px) {
// }

@media screen and (max-width: 1220px) {
  .comingsoontwo-banner {
    min-height: 100vh;
  }
  .time-line-div-modify {
    margin-bottom: 0;
  }
  .copy-right-div-modify {
    // padding-bottom: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .comingsoontwo-banner {
    // height: 100%;
    .container-fluid {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .time-line-div-modify {
    padding-bottom: 15px;
  }
  .f-content-modify {
    padding: 15px;
  }
  // .copy-right-div-modify {
  //   padding-top: 230px;
  // }
}

@media screen and (max-width: 991px) {
  .comingsoontwo-banner {
    min-height: 100vh;
  }
  .copy-right-div-modify {
    padding-top: 230px;
  }
}

@media (max-width: 768px) {
  .comingsoontwo-banner {
    .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 575px) {
  .comingsoon-main {
    // padding-top: 10px;
    gap: 30px;
  }
  .f-content-modify {
    padding: 10px;
  }
  .email-input {
    .CustomInputIntroduction {
      min-width: 200px;
    }
  }
  .comingsoontwo-banner {
    height: 100%;
  }
}

@media screen and (max-width: 420px) {
  .comingsoontwo-banner {
    height: 100%;
  }
  .copy-right-div {
    padding-bottom: 13px;
  }
}

@media screen and (max-width: 375px) {
  .comingsoontwo-banner {
    height: 100%;
  }
  .time-line-div-modify {
    padding: 0;
  }
  .timeline-main {
    padding-top: 40px;
  }
}

@media screen and (max-width: 320px) {
  .email-input {
    .CustomInputIntroduction {
      min-width: 150px;
    }
  }
}
