@import "../../data/styleConstants.scss";

.banner {
  background-image: url("../Images/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background: linear-gradient(213deg, $brand500 0%, $blueEx 99.97%);
    z-index: 1;
  }

  .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.inner-content {
  // padding-top: 130px;

  h1 {
    color: $SecondaryDark;
    font-weight: 600;
    letter-spacing: -1.28px;
    text-transform: uppercase;
    margin-bottom: 0;
    word-wrap: break-word;
  }
  .mini-content {
    max-width: 890px;
  }
}

.inner-content-modify {
  padding: 0px;
  text-align: center;

  h4 {
    color: $white;
    margin-bottom: 0;
  }
}

.input-area {
  padding-bottom: 36px;
}

.social-media-div {
  h5 {
    color: $white;
    margin-bottom: 14px;
  }
}

.f-content {
  padding-top: 39px;

  p {
    max-width: 899px;
  }
}

.f-content-modify {
  padding: 36px;

  p {
    max-width: 1220px;
    margin-bottom: 0px;
  }
}
.side-content-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.side-content-img img {
  width: 100%;
  height: auto;
  margin-bottom: 55px;
}

.copy-right-div {
  text-align: end;
  // padding-bottom: 52px;
  z-index: 2;
  position: relative;
  padding-right: 70px;
  p {
    margin-bottom: 0px;
  }
}

.copy-right-div-modify {
  padding-top: 90px;
  // padding-bottom: 21px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .side-content-img {
    img {
      max-height: 400px;
    }
  }
  .time-line-div h1 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 1280px) {
  .inner-content {
    padding-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .container-fluid {
    padding: 0px 20px;
  }
  .copy-right-div {
    padding-bottom: 10px;
  }
  .side-content-img img {
    max-width: 350px;
    max-height: 350px;
  }

  .time-line-div {
    padding-bottom: 50px;
  }
  .banner {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .container-fluid {
    padding: 0px 15px;
  }
  .input-area {
    padding-bottom: 20px;
  }
  .side-content-img {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .side-content-img {
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 5px;
      max-width: 300px;
      max-height: 300px;
    }
  }

  .inner-content {
    h1 {
      font-size: 45px;
    }

    .mini-content {
      line-height: 23px;
      font-size: 12px;
    }
  }

  p {
    font-size: 15px;
    line-height: 27px;
  }
  .copy-right-div {
    padding-right: 40px;
  }
  .banner {
    height: 100%;
  }
}

@media screen and (max-width: 575px) {
  .inner-content h1 {
    font-size: 32px;
  }
  .time-line-div {
    h1 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  .side-content-img img {
    max-width: 250px;
    max-height: 250px;
  }
  .copy-right-div {
    p {
      margin-bottom: 70px;
    }
  }
}

@media screen and (max-width: 420px) {
  .copy-right-div {
    p {
      margin-bottom: 40px;
      padding-top: 10px;
    }
  }
}
@media screen and (max-width: 375px) {
  .copy-right-div {
    p {
      margin-bottom: 10px;
    }
  }
  .email-input {
    .submit-button {
      width: 130px;
    }
  }
}

@media screen and (max-width: 320px) {
  .inner-content h1 {
    font-size: 32px;
  }
  .time-line-div {
    h1 {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .side-content-img img {
    max-width: 320px;
    max-height: 320px;
  }
  .copy-right-div {
    padding-right: 30px;
  }
}
