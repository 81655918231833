@import "../../data/styleConstants.scss";

.social-media-icons {
  display: flex;
  gap: 10px;

  .icon-wraper {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    width: 48px;
    height: 48px;
    padding: 3px 1px;
  }
}

@media screen and (max-width: 991px) {
  .social-media-icons {
    .icon-wraper {
      width: 42px;
      height: 42px;
    }
  }
}
@media screen and (max-width: 767px) {
  .social-media-icons {
    .icon-wraper {
      width: 38px;
      height: 38px;
    }
  }
}
