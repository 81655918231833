// _colors.scss

$white: #ffffff;
$offWhite: #f0f0f0;
$offWhiteTwo: #f4f6f7;

$brand50: #effbff;
$brand100: #def5ff;
$brand200: #b6efff;
$brand300: #75e4ff;
$brand400: #2cd8ff;
$brand500: #00b9e9;
$brandrepeat500: #00b9e9;
$brand600: #009ed4;
$brand700: #007eab;
$blue50: #f1f8fe;
$blue100: #e1effd;
$blue200: #bddefa;
$blue300: #83c5f6;
$blue400: #41a7ef;
$blue500: #188cdf;
$blue600: #0b6ebe;
$blue700: #0b5ea5;
$blueEx: #124067;

$Secondary: #fff686;
$SecondaryDark: #ffd600;

$danger: #d9534f;
$success: #07a85b;
$warn: #f0ad4e;
$info: #00bbe6;

$middleP: #f8f8f8;
$middleBlack: #0d0e10;
