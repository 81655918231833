.logo {
  max-width: 100%;
}

@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 1399px) {
}

@media screen and (max-width: 1199px) {
  .logo {
    max-width: 40%;
  }
}

@media screen and (max-width: 991px) {
  .logo {
    max-width: 40%;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 380px) {
}
