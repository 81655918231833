@import "../../data/styleConstants.scss";

.time-line-div-modify {
  padding-bottom: 60px;
  h1 {
    margin-bottom: 0px;
  }
}

.timing {
  margin-bottom: 53px;
  display: flex;
  justify-content: end;

  li {
    font-size: 64px;
    color: $white;
    line-height: 77.45px;
    font-weight: 500;
    list-style: none;
    text-align: center;
    position: relative;
    padding: 0 47px;

    strong {
      font-weight: 500;
    }

    &:last-child {
      padding-right: 0;
    }

    &:before {
      background: $white;
      height: 57px;
      content: "";
      width: 1px;
      position: absolute;
      left: 0;
      top: 22px;
    }

    &:first-child:before {
      content: none;
    }

    span {
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      display: block;
    }
  }

  ul {
    display: flex;
  }
}

@media screen and (max-width: 1320px) {
}
@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
  // .time-line-div {
  //   h1 {
  //     padding-right: 15px;
  //     padding-left: 15px;
  //   }
  // }
  .timing {
    padding-top: 150px;
    li {
      padding: 0 25px;

      &::before {
        height: 45px;
        top: 12px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .inner-content {
    padding-top: 0px;
  }

  .timing {
    display: flex;
    padding-top: 35px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .f-content {
    padding-top: 20px;
    p {
      margin-bottom: 0px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }

  .side-content-img {
    img {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 768px) {
  .timing {
    padding: 60px 25px 25px 25px;
    margin-bottom: 0px;

    li {
      padding: 0 35px;
    }
  }
  .timing li:before {
    background: #ffffff;
    height: 40px;
    content: "";
    width: 1px;
    position: absolute;
    left: 0;
    top: 15px;
  }
  .inner-content {
    padding-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .inner-content {
    padding-top: 30px;
  }
  .timing h1 {
    font-size: 24px;
  }

  .timing li:first-child {
    padding-left: 0;
  }
}
@media screen and (max-width: 320px) {
  .timing h1 {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }
  .inner-content {
    padding-top: 30px;
  }
  .timing li {
    padding: 0 20px;
  }
}
