@import "../../data/styleConstants.scss";
.email-input {
  display: flex;
  align-items: center;

  .CustomInputIntroduction {
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .submit-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 50px;
    background-color: #00b9e9;
    display: flex;
    width: 146px;
    padding: 7px 5px;
    align-items: center;
    color: var(--Colors-Base-white, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  @media screen and (max-width: 575px) {
    .CustomInputIntroduction {
      min-width: 235px;
    }
    .submit-button {
      font-size: 14px;
      width: 115px;
    }
  }
}
